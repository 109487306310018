/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500&display=swap');
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');
@import '@angular/material/theming';
@include mat-core();
$ins-palette: (
 50: #F4F4F4,
 100: #e3b933,
 200: #79D2D8,
 300: #F8D06B,
 // ... continues to 900
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   // ... continues to 900
 )
);
$my-app-primary: mat-palette($mat-indigo, 500, 700, 900);
$my-app-accent:  mat-palette($mat-indigo, 200);
$my-app-warn:    mat-palette($mat-red, 500);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
// .alternate-theme {
//   $alternate-primary: mat-palette($mat-light-blue);
//   $alternate-accent:  mat-palette($mat-yellow, 400);
//   $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
//   @include angular-material-theme($alternate-theme);
// }
$white: #ffffff;
$label: #000000;
$primary: #e3b933;
$primary-light: #e3b933;
$primary-light-bg:rgba(3,169,244,0.1);
$accent:#000000;
$accent-shadow: rgba(210,210,210,0.3);
$muted: #A8AEB2;
$disabled-bg: rgba(168, 174, 178, 0.3);
$primary-light: #0D3F5E;
$primary-light-bg: rgba(95, 93, 93, 0.1);
$warn:#ef5350;
$primary-shadow: rgba(10, 18, 142, 0.3);
$disabled:#A8AEB2;
html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins', sans-serif;background-color: #FFFFFF;font-size: 18px;}
*, .mat-button-toggle{font-family: 'Poppins', sans-serif;box-sizing: border-box;}
$border-color: #A8AEB2;
.text-center{text-align: center;}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{padding: 0 0.5em;}
// material overwrite
button, a{text-decoration: none!important;
  &:focus{outline: none;box-shadow: none;}
  &.mat-raised-button{border: 2px solid #e4e4e4;
    &:hover{background-color: $primary-light;color: #ffffff;}
  }
  &.mat-stroked-button{border-width: 2px;border-radius: 5px;transition: all 200ms ease;background-color: transparent; min-height:38px;
    &.primary-outline{border: 2px solid $primary;width: 160px;
      &:hover{box-shadow: 0 4px 30px $accent-shadow;}
      &:focus{box-shadow: 0 4px 30px $accent-shadow;}
    }
    &.mat-primary{border-width: 0;}
    &.basic-outline{width: 160px;border: 2px solid $label;
      &:hover{border-color: $primary;color: $primary;box-shadow: 0 4px 30px $primary-light-bg;}
      &:focus{box-shadow: 0 4px 30px $primary-light-bg;}
    }
    &.mat-flat-button{
      &:hover{background-color: $primary;color: $white;}
    }
  }
  .mat-button-focus-overlay{background: $white;}
  &.mat-flat-button.mat-accent{background-color: $primary;color: #F4F4F4;}
  .mat-button-focus-overlay{background: $white;}
}
a{
  &.mat-stroked-button{display: flex;align-items: center;justify-content: center;min-height: 38px;}
}
.container{padding: 0 0.5em;}
label{margin-bottom: 0;}
.primary{color: $primary;}
.tooltip-icon{
  .mat-icon{font-size: 20px;}
}
body{
    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {padding: 0;}
  .mini-form {
    .mat-button-toggle{
      &:hover{background-color: $primary-light-bg;}
    }
    .mat-button-toggle-checked{background-color: $primary;color: $white;
      &.mat-button-toggle{
        &:hover{background-color: $primary;opacity: 0.85;}
      }
      .mat-button-toggle-focus-overlay{border-bottom: 0;}
    }
  }
  .autocomplete{
    .mat-form-field-wrapper{
      .mat-error{opacity: 0;}
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple{background-color: #bdbdbd;}
    .mat-form-field.mat-form-field-invalid .mat-form-field-label, .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple{color: rgba(0, 0, 0, 0.54);}
  }
  .mat-button-wrapper{line-height: 24px;font-weight: 600;}
  .mat-slider-horizontal .mat-slider-thumb-label{width: 40px;height: 40px;top:-50px;}
  .needs-box{
    .mat-button-wrapper{width: 100%;display: block;}
  }
  input.mat-input-element{color: $primary;}
  .mat-horizontal-stepper-header::before, .mat-horizontal-stepper-header::after, .mat-stepper-horizontal-line{border-top-color: $primary;}
  .mat-toolbar-single-row{background-color: transparent;height: 70px;}
  .mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {line-height: 2em;height: 2.25em;font-size: 16px;color: $primary;}
  .mat-select-value{color: $primary;}

  .mat-slider-horizontal {width: 100%;
    .mat-slider-track-background, .mat-slider-ticks, .mat-slider-track-fill, .mat-slider-wrapper, .mat-slider-track-wrapper, .mat-slider-ticks-container{height: 10px;border-radius: 1rem;}
    &:before{content:'';position: absolute;left: -5px;top:40%;width: 18px;height: 18px;background-color: $primary;border-radius: 100%;}
    &:after{content:'';position: absolute;right: 4px;top:40%;width: 18px;height: 18px;background-color: #bdbdbd;border-radius: 100%;}
  }
  .mat-slider-thumb {bottom: -14px;width: 28px;height: 28px;
    &:after{content:'|||';position: absolute;left: 0;right:0;color: white;font-size: 10px;line-height: 22px;width: 22px;letter-spacing: 1px;text-align: center;}
  }
  .mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label{background-color: $primary;}
  .mat-slide-toggle-thumb{
    position: relative;background-color: $border-color;height: 18px;width: 18px;
    &:after{content:'|||';position: absolute;left: 0;right:0;color: white;font-size: 9px;line-height: 18px;width: 20px;letter-spacing: 1px;text-align: center;}
  }
  .mat-slide-toggle-bar{height: 25px;border-radius: 12px;width: 60px;background-color: $primary-light-bg;border:2px solid $primary;}
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(36px, 0, 0);
  }
}
.mat-step-header{
  .mat-step-icon{background-color: $primary;color: $white;}
  .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{background-color: $primary;color: $white;}
  .mat-step-header .mat-step-icon{color: $white;}
  @media (max-width: 576px){
  .mat-step-text-label {
    font-size: 12px;
    font-weight: 500;
    }
  }
}
.question-content{
  .mat-form-field{display: block;}
}
.stepper-width{
  .mat-horizontal-stepper-header-container{width: 50%;margin: auto;}
}
.mat-stepper-horizontal{background-color: transparent;}
.mat-horizontal-stepper-header-container{width: 65%;margin: auto;}
.eapp .mat-horizontal-stepper-header-container{width: 100%;}
.question-content{

  // .mat-select{line-height: 48px;}

  .mat-slide-toggle-thumb-container{top:1px;}
  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{border-color: $primary;}
  .mat-checkbox-label{font-size: 14px;font-weight: 600;line-height: 18px;color: $label;}
  .mat-tab-header{width: 90%;margin: 0 auto 1em;}
  .form-group{
    .mat-checkbox-layout{margin-bottom: 5px;}
  }
}
.w-60{width: 60%;}
.get-quote{
  .owl-carousel{
    .owl-stage{margin: auto;}
  }
}
body {
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{background-color: $accent;}
}
.owl-theme{
  .owl-nav{position: absolute;width: 100%;top: 20%;margin: 0;
    .owl-prev{position: absolute;left: -30px;margin: 0;
      span{font-size: 32px;line-height: 30px;font-weight: 600;}
    }
    .owl-next{position: absolute;right: -30px;margin: 0;
      span{font-size: 32px;line-height: 30px;font-weight: 600;}
    }
  }
  .owl-stage-outer{margin-bottom: 1em;
    .owl-item{
      width: fit-content;
      .right-border{padding-right: 1em;border-right: 2px dashed $primary;}
      // .left-broder{padding-left: 1em;border-left: 2px dashed $accent;}
    }
  }
  .owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span{background: $accent;}
  .owl-dots .owl-dot span{background: rgba(255,89,170,0.2);}
}
.get-quote .owl-carousel .owl-item {
    min-height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.owl-item {
    width: 100%;
    .term-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-color;
      background-color: $white;
      text-align: center;
      position: relative;
      cursor: pointer;
      height: 120px;
      width: 110px;
      @media screen and (max-width: 576px) {
        width: auto;
        padding: 1.25em 1em;
      }
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: $accent;
        line-height: 18px;
        padding-bottom: 0.5em;
        white-space: nowrap;
      }
      p {
        padding-bottom: 0.5em;
        margin-bottom: 0 !important;
        font-size: 15px;
        color: $label;
        line-height: 18px;
        font-weight: 600;
        white-space: nowrap;
      }
      .text-muted {
        color: $muted;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
      }
      .virtual-checked {
        position: absolute;
        top: 0.05em;
        // right: 0.25em;
      }
      &:hover {
        box-shadow: 0 4px 34px $primary-shadow;
      }
      &.selected {
        border: 2px solid $primary;
        box-shadow: 0 4px 15px $primary-shadow;
      }
      &.disabled {
        border: 2px solid $disabled;
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          box-shadow: none;
        }
      }
    }
}
.get-quote{
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label{padding: 10px 0 0;font-weight: 600;font-size: 15px;color: $label;}
  .mat-step-icon-content, .mat-step-icon .mat-icon{font-size: 11px;font-weight: bold;}
  .mat-step-icon-content .mat-icon{font-size: 16px;font-weight: 500;}
  .mat-step-icon{
    &.mat-step-icon-state-done {background-color: $accent;}
  }
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon{text-align: center;
    //border: 1px solid rgba(18,52,74,0.1);background: radial-gradient(16px, rgba(18,52,74,0.85), rgba(18,52,74,0.1));
    position: relative;
    padding: 1px;
    width: 26px;
    height: 26px;
    /*&.mat-step-icon-state-done{border: 1px solid $accent-shadow;background: radial-gradient(10px, rgba(255,89,177,0.8), rgba(255,89,177,0.2));position: relative;
      padding: 5px;
      width: 30px;
      height: 30px;
      &:after{content: '';background: radial-gradient(16px, rgba(255,89,177,0.8), rgba(255,89,177,0.2));border: 1px solid rgba(255, 89, 170, 0.2);
        height: 20px;
        width: 20px;
        position: absolute;
        left: 4px;
        top: 4px;
        border-radius: 100%;
        z-index: -9;
      }
    }
    &:after{
      content: '';background: radial-gradient(12px,  rgba(18,52,74,0.5), rgba(18,52,74,0.1));border: 1px solid rgba(18,52,74,0.1);
      height: 20px;
      width: 20px;
      position: absolute;
      left: 4px;
      top: 4px;
      border-radius: 100%;
      z-index: -9;
    }*/
  }
  .product-item{
    .product{
      .mat-checkbox-layout{margin: 0;}
    }
  }

}
// address form
body{
  .address{
    .form-control{
      &:hover{border-width: inherit;}
      &:focus{box-shadow: none;}
    }
    .mat-form-field-appearance-standard .mat-form-field-ripple{height: 1px;}
    .mat-form-field-flex{padding: 0;
      .mat-form-field-outline {
        .mat-form-field-outline-start,  .mat-form-field-outline-gap, .mat-form-field-outline-end{border:0;border-radius: 0;border-bottom: 1px solid;}
      }
    }
    .mat-form-field-infix{padding: 0.4375em 0;}
    mat-card-title{display: block;}
    .mat-form-field{min-width: 25%;}
  }
}

.cdk-overlay-container {
    .mat-option {
        .mat-option-text {
            display: contents;
        }
    }
} 

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.contact-form .mdc-text-field {
    padding: 0;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent;
}

.mat-slider-horizontal .mat-slider-ticks-container {
    background-color: rgba(0,0,0,.2);
}

.mat-slider-horizontal .mat-slider-track-wrapper {
    border-radius: 1rem;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper, .mat-mdc-text-field-wrapper {
    padding: 0 !important;
}

.mat-slider-horizontal .mat-slider-ticks {
    border-radius: 1rem;
}

.w-90{width: 90%;}
.col-sm-4{width: 33.33%;padding: 0 0.5em;}
@media (max-width: 768px) {
  .sm-w-100{width: 100%;}
  .owl-theme .owl-nav .owl-prev{left: -20px;}
  .owl-theme .owl-nav .owl-next{right: -20px;}
  .get-quote .owl-carousel{
    // .owl-stage-outer{
    // .owl-item{width: auto!important;}
    // }
  }
}
@media (max-width: 576px) {
  .owl-theme .owl-nav {display: none;}
  hr{margin-top: 0;}
  .col-xs-6{width: 50%;}
    .mat-horizontal-stepper-header-container{width: 100%;}
    .mat-horizontal-content-container{padding: 0 0 30px 0 !important;}
    .product-item{
      .product{
        .mat-checkbox-label{display: none;}
        button{
          span {font-size: 12px;}
        }
      }
    }
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header{
      pointer-events: none!important;
      padding: 20px 8px 0;
      .mat-step-label{font-size: 11px;font-weight: 500;}
    }
  button{
    &.mat-stroked-button{min-height: 38px;
      &.primary-outline{width: 140px;}
      &.basic-outline{width: 140px;border: 2px solid $label;}
    }
  }
}
@media (min-width: 769px) {
  .get-quote{
    .owl-carousel{
      .owl-item{min-height: auto;}
      // .owl-stage{width: auto!important;}
    }
  }
  body{
    .mat-tooltip{font-size: 16px;min-width: 340px;}
  }
}
@media(min-width:768px) and (max-width: 992px) {
  .eapp {
    .mat-horizontal-stepper-header-container{width: 105%;margin-left: -2.5%;}
  }
}
@media (max-width:380px) {
  body{
    .mat-button-wrapper{line-height: 24px;font-size: 13px;}
    .question-content .mat-checkbox-label{font-size: 12px;}
  }
}
@media (max-width:340px) {
  .question-content h4{font-size: 14px;}
  body{
    .question-content .mat-checkbox-label{font-size: 11px;}
  }
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
.owl-item {
  // width: fit-content !important;
  // margin: 0 8px !important;
}
.owl-stage {
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.health-component {
  .mat-checkbox-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #000000;
    @media screen and (max-width:576px){
      font-size:13px;
    }
  }
  .mat-button-toggle-appearance-standard{
    .mat-button-toggle-label-content{padding: 0 10px;font-size: 14px;}
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{line-height: 46px;}
  .mat-button-toggle-standalone, .mat-button-toggle-group{max-height: 48px;font-size: 15px;}
  .mat-form-field-appearance-legacy .mat-form-field-label{font-size: 14px;line-height: 2;}
  .mat-form-field-subscript-wrapper {
    font-size: 13px !important;
  }
}